import "../../App.css";
import { Container, TextField, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/FooterComponent";
// import { useNavigate } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: "OpenSans, Arial, sans-serif",
  },
});

function PasswordResetView() {
  //   const { token } = useParams(); // Extracts token from the URL
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState(null);
  const [success, setSuccess] = useState(false);
  // const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform form-level validation
    console.log(password);
    // Submit the form data
    try {
      const data = {
        password: password,
        token: token,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/password_reset/confirm/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      // Handle the response data
      console.log(responseData);
      // navigate("/");
      setSuccess(true);
      alert("Password updated successfully!")

    } catch (error) {
      console.error("Error:", error);
      alert("Password updated failed")
    }
  };
  useEffect(() => { }, [success]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Container>
          <h1>Password reset</h1>
          {
            success ? <div><Typography variant="h5">Password updated successfully</Typography></div> :
              <div>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </div>
          }


          <Footer />
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default PasswordResetView;
