import React from 'react';
import {
    Container,
    Typography,
    Box,
    List,
    ListItem,
    Link,
    TextField,
    Button
} from '@mui/material';

const SupportView = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Support
            </Typography>
            <Typography variant="body1" gutterBottom>
                If you need assistance with the Sidewalk app, you've come to the right place. Please find support options below:
            </Typography>

            {/* Contact Information */}
            <Box my={2}>
                <Typography variant="h5" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1">
                    If you have any questions or need further assistance, please reach out to our team:
                </Typography>
                <List>
                    <ListItem>Email: <Link href="mailto:hello@sidewa.lk" sx={{ ml: 1 }}>hello@sidewa.lk</Link></ListItem>
                </List>
            </Box>

            {/* FAQ Section */}
            <Box my={2}>
                <Typography variant="h5" gutterBottom>
                    Frequently Asked Questions (FAQ)
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1">1. How do I share my location?</Typography>
                    <Typography variant="body2">
                        To share your location, tap the "Start Location" button in the Sidewalk app. You can choose to share your location for 1 to 8 hours.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">2. Why does Sidewalk need my location?</Typography>
                    <Typography variant="body2">
                        Sidewalk uses your location to help users find real-time locations of vendors on the platform.
                    </Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="subtitle1">3. What happens if I don’t grant location access?</Typography>
                    <Typography variant="body2">
                        If you do not grant location access, you won't be able to share your real-time location, and certain features of the app will not work properly.
                    </Typography>
                </Box>
            </Box>

            {/* Feedback Form */}
            <Box my={2}>
                <Typography variant="h5" gutterBottom>
                    Submit Feedback or Report an Issue
                </Typography>
                <Typography variant="body1">
                    Please use the following links to submit feedback, report an issue, or request account deletion:
                </Typography>
                <List>
                    <ListItem>
                        <Button
                            variant="contained"
                            color="primary"
                            href="https://forms.gle/hCGJQGnUriiTtH9n9"
                            target="_blank"
                            sx={{ textTransform: 'none', mr: 2 }}
                            style={{
                                borderRadius: 35,
                                backgroundColor: "#005F73",
                                padding: "12px 24px",
                                fontSize: "10px",
                            }}
                        >
                            Feedback Form
                        </Button>
                    </ListItem>
                    <ListItem>
                        <Button
                            variant="contained"
                            color="secondary"
                            href="https://forms.gle/touAg5dYaA8yo3Dr8"
                            target="_blank"
                            sx={{ textTransform: 'none', mr: 2 }}
                            style={{
                                borderRadius: 35,
                                backgroundColor: "#005F73",
                                padding: "12px 24px",
                                fontSize: "10px",
                            }}
                        >
                            Report an Issue
                        </Button>
                    </ListItem>
                    <ListItem>
                        <Button
                            variant="contained"
                            color="error"
                            href="https://forms.gle/21duaqdHDyCZCdQe9"
                            target="_blank"
                            sx={{ textTransform: 'none', mr: 2 }}
                            style={{
                                borderRadius: 35,
                                backgroundColor: "#005F73",
                                padding: "12px 24px",
                                fontSize: "10px",
                            }}
                        >
                            Delete Account Request
                        </Button>
                    </ListItem>
                </List>
            </Box>

            <Typography variant="body1">
                We are here to help you with any issues you might be experiencing. Thank you for using Sidewalk!
            </Typography>
        </Container>
    );
}

export default SupportView;
