export const theme = {
    colors: {
        primary: "#005F73",
        primaryLight: "#0A9396",
        secondary: "#EE9B00",
        secondaryDark: "#CA6702",
        background: "white",
        black: "#001219",
        yellow: "#EE9B00",
        red: "#AE2012",
    }
}