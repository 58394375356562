import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import VendorView from "./views/Vendor/VendorView";
import PasswordResetView from "./views/PasswordReset/PasswordResetView";
import PrivacyPolicyView from "./views/PrivacyPolicy/PrivacyPolicyView";
import TermsOfServiceView from "./views/TermsOfService/TermsOfServiceView";
import HomeView from "./views/Home/HomeView";
import SupportView from "./views/Support/SupportView";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomeView />} />
          <Route exact path="/:slug" element={<VendorView />} />
          <Route path="/_/password-reset" element={<PasswordResetView />} />
          <Route path="/_/privacy-policy" element={<PrivacyPolicyView />} />
          <Route path="/_/terms-of-service" element={<TermsOfServiceView />} />
          <Route path="/_/support" element={<SupportView />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
