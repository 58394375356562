import React from "react";
import "react-device-frameset/styles/marvel-devices.min.css";
// import "react-device-frameset/styles/devices.min.css";
import Grid from "@mui/material/Grid2";
import "../../App.css";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
} from "@mui/material";
import logo_black_with_text_landscape from "../../assets/logo_black_with_text_landscape.png";
import iphone_mockup from "../../assets/Iphone_mockup_v3.png";
import JoinWaitingList from "../components/JoinWaitingList";
import Footer from "../components/FooterComponent";
import { useMediaQuery } from "react-responsive";

const theme = createTheme({
  typography: {
    h3: {
      fontFamily: "Poppins",
    },
    h5: {
      fontFamily: "Poppins",
    },
    fontFamily: "OpenSans, Arial, sans-serif",
  },
});

const HomeView = () => {
  console.log("HomeView");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Container maxWidth="xl">
          <>
            <AppBar
              position="static"
              elevation={0}
              color="transparent"
              alignItems="center"
              justifyContent="center"
            >
              <Toolbar>
                <Box
                  component="img"
                  src={logo_black_with_text_landscape}
                  alt="Company Logo"
                  sx={{ height: 50, marginRight: 2 }}
                />
              </Toolbar>
            </AppBar>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                mt={4}
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  size={{ xs: 12, md: 6 }}
                  //   sx={{ backgroundColor: "red" }}
                >
                  {isDesktopOrLaptop ? <Typography
                    variant="h3"
                    my={3}
                    sx={{
                      color: "#212427",
                      fontFamily: "Poppins",
                    }}
                  >
                    Explore what is in your neighbourhood, with sidewalk.
                  </Typography> : <Typography
                    variant="h4"
                    my={3}
                    sx={{
                      color: "#212427",
                      fontFamily: "Poppins",
                    }}
                  >
                    Explore what is in your neighbourhood, with sidewalk.
                  </Typography>}
                  
                  
                  <br />
                  <JoinWaitingList />
                </Grid>
                <Grid
                  item
                  size={{ xs: 12, md: 6 }}
                  padding={2}
                  //   sx={{ backgroundColor: "blue" }}
                >
                  <img
                    alt="iphone_mockup"
                    src={iphone_mockup}
                    className="iphone_mockup"
                    width="100%"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default HomeView;
